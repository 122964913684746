<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-7 col-xxxl-7 maxWidthContainer">
      <div class="card border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing col-12">
          <div class="d-flex align-items-center flex-column">
            <img class="me-2" src="../../assets/images/favicon.png" width="80" alt="Layer">
            <h4 class="text-black fw-bold mb-0 text-center">
              Quase lá...
            </h4>
          </div>
          <form ref="formRegister" @submit.prevent="submitRegister">
            <div class="row d-flex">

              <h4 class="d-flex mb-4 justify-content-center text-center">Cadastre-se para não perder a resposta à sua mensagem anônima:</h4>
              <!-- <p class="d-flex mb-4 justify-content-center fs-16">Crie um login para ter acesso ao chat anônimo depois:</p> -->
              
              <div class="form-group mt-4 mb-15 mb-sm-20 mb-md-25 col-12">
                <label class="d-block text-black fw-semibold mb-10 fs-16">
                  Digite seu email
                </label>
                <input
                  ref="email"
                  type="email"
                  name="email"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="Ex: exemplo@gmail.com"
                  @keyup="changeInformationInputs('email')"
                />
              </div>

              <div class="form-group mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10 fs-16">
                  Crie uma senha
                </label>
                <input
                  ref="password"
                  type="password"
                  name="password"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="**************"
                  @keyup="changeInformationInputs('password')"
                />
              </div>

              <div class="form-group col-12">
                <div ref="error_password_message" class="error-password-message fs-16">
                  As senhas são diferentes !
                </div>
              </div>
              
              <div class="form-group col-12">
                <div ref="error_password_message_caracters" class="error-password-message-caracters fs-16">
                  A senha precisa ter pelo menos 1 número e 1 caracter especial !
                </div>
              </div>

              <div class="form-group col-12">
                <button
                  class="default-btn transition border-0 fw-medium text-white rounded-1 fs-16 bg-success d-block w-100"
                  type="submit"
                  ref="button_submit_register"
                >
                  Cadastrar
                </button>
              </div>

              <div class="form-group col-12">
                <div class="message-error-register" v-if="getErrorRegister != ''">
                  {{ getErrorRegister }}
                </div>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ref } from 'vue'

export default {
  name: "CreateAccessStepContent",
  data() {
    return {
      message: "",
      maxLength: 1000,
    };
  },
  computed:{
    ...mapGetters([
      'getErrorRegister'
    ]),
    remainingCharacters() {
      return this.maxLength - this.message.length;
    }
  },
  mounted(){
    if(localStorage.getItem("secretadmirermessage") == null){
      window.location.href = "/register";
    }
  },
  methods: {
    ...mapActions([
        "sendRegister",
        "sendRegisterTwo"
    ]),
    changeInformationInputs(element){
      this.$refs[element].style.borderColor = "#dedee4";
    },
    applyMaskCpf(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, '');

      if (value.length <= 11) {
        value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }

      input.value = value;
    },
    applyMaskPhone(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, '');

      if (value.length > 10) {
        value = value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } else {
        value = value.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
      }

      input.value = value;
    },
    hasLetters(str) {
      return /[a-zA-Z]/.test(str);
    },
    checkPassword(password){
      return /[0-9]/.test(password) && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(password);
    },
    submitRegister(){
      const formData = new FormData(this.$refs['formRegister']);
      var data = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }

      let error = false;
      
      if(data.email == ""){
        this.$refs["email"].style.borderColor = "red";
        error = true;
      }
      
      if(data.password == ""){
        this.$refs["password"].style.borderColor = "red";
        error = true;
      }

      if(!this.checkPassword(data.password)){
        this.$refs["error_password_message_caracters"].style.display = "flex";
        error = true;
      } else {
        this.$refs["error_password_message_caracters"].style.display = "none";
      }

      if(error){
        return;
      }

      this.$refs.button_submit_register.innerHTML = "Carregando...";
      this.$refs.button_submit_register.disabled = true;

      if(localStorage.getItem('secretadmirermessage') != null){
        const secretAdmirerMessage = JSON.parse(localStorage.getItem('secretadmirermessage'));

        if(secretAdmirerMessage != null){
          if(secretAdmirerMessage.id != null){
            data.secret_message_id = secretAdmirerMessage.id;
          }
        }
      }

      this.sendRegisterTwo(data).then(() => {
        this.$refs.button_submit_register.innerHTML = "Criar conta";
        this.$refs.button_submit_register.disabled = false;
      });
    }
  }
};
</script>

<style scoped>
.character-counter {
  margin-top: 5px;
  font-size: 0.9em;
  color: gray;
}
</style>