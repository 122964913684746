<template>
  <div class="modal fade" id="verticallyCenteredModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header">
                  <h1 class="modal-title fs-5">Deslogar</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              
              <div class="modal-body fs-16">
                Tem certeza que deseja deslogar ? Para acessar novamente você precisará informar email e senha
              </div>

              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary fs-16" data-bs-dismiss="modal">Cancelar</button>
                  <button type="button" class="btn btn-primary fs-16" @click="logoutUser">Deslogar</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import api from '../../config/axios.js';

export default {
  name: "LogoutModal",
  data () {
      return {}
  },
  methods: {
    logoutUser(){
      const token = localStorage.getItem("secretadmirertoken");
      if(token != undefined && token != null && token){
        api.axios.post("/api/logout", null, {headers: { Authorization: `Bearer ${token}` }}).then(function(response){
          if(response.status == 200){
            localStorage.removeItem("secretadmirertoken");
            window.location.href = "/login";
          }
        }).catch(_error => {
          // console.log("_error: ", _error);
        });
      } 
    }
  }
};
</script>