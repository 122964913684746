<template>
  <img
    src="../../assets/images/city-background.jpg"
    alt="contact-image"
    class="image-background-login"
  />
  <!-- <RegisterContent /> -->
  <RegisterStepsContent />
</template>

<script lang="ts">
import { defineComponent } from "vue";

// import RegisterContent from "../../components/Authentication/RegisterContent.vue";
import RegisterStepsContent from "../../components/Authentication/RegisterStepsContent.vue";

export default defineComponent({
  name: "RegisterPage",
  components: {
    // RegisterContent,
    RegisterStepsContent,
  },
});
</script>