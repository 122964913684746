<template>
  <div class="row style-custom-chat">
    <div class="col-12">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing chat-card radius-7 total-height customize-area-chat"
        ref="containerChatMessage"
      >
        <div
          class="card-head box-shadow bg-white d-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25 radius-7 card-head-custom"
        >
          <div class="d-flex align-items-center">
            
            <router-link
              to="/messages"
              class="text-decoration-none dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition me-4 fw-bold"
              @click="resetMessagesChat()"
            >
            <i class="flaticon-left-arrow"></i>
            </router-link>

            <div class="ms-15">
              <span class="d-block text-black fs-16 fw-medium"
                >{{ getWhatsapp }}</span
              >
            </div>
          </div>
        </div>
        <div class="card-body p-15 p-sm-20 p-md-25 custom-body-chat">
          <div id="chatMessage" class="chat-list" @scroll="handleScroll" ref="chatContainer">

            <div class="loading" v-if="loading">
              <h3>Carregando...</h3>
            </div>
            
            <div v-for="(value, key) in getMessagesChat" :key="key" class="chat-item position-relative" v-bind:class="{right: value.user_id == this.$route.params.userId}">

              
              <div class="message position-relative">
                <div class="inner color-anonimo-chat">
                  <p class="font-size-custom-message lh-base style-text-custom">
                    {{ value.message }}
                  </p>
                  <span class="d-block fs-16 fw-medium">
                    <div>
                      <span class="fs-12 fw-normal ms-5 font-data-custom">{{ value.date }}</span>
                    </div>
                  </span>
                </div>
              </div>


              {{ setLastUserMessage(value.user_id) }}
            </div>

          </div>
          <form class="style-form-custom" @submit.prevent="sendMessage()">
            <div class="character-counter">
              {{ remainingCharacters }} caracteres restantes
            </div>
            <div class="write-your-message position-relative custom-send-message customize-chat-mobile">
              <div class="write-message position-relative custom-area-input">
                <input
                  type="text"
                  class="input-message d-block w-100 text-black input-message-custom"
                  placeholder="Digite aqui a sua mensagem"
                  ref="message"
                  v-model="message"
                  :maxlength="maxLength"
                  @focus="scrollToBottom"
                />
              </div>
              <div class="buttons-list area-button-send-message">
                <button
                  type="submit"
                  class="border-0 d-inline-block position-relative text-center transition active button-send-message"
                >
                  <i class="flaticon-airplane"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { right } from '@popperjs/core';
import { mapGetters, mapActions } from 'vuex';
import Pusher from 'pusher-js';

export default {
  name: "ChatContent",
  data: function () {
    return {
      currentPage: 1,
      userId: "",
      loading: true,
      pusher: null,
      page: 0,
      message: "",
      maxLength: 1000,
      abaAtiva: true,
      mensagensNotificadas: new Set()
    };
  },
  computed: {
      ...mapGetters([
        'getMessagesChat',
        'getWhatsapp'
      ]),
      remainingCharacters() {
        return this.maxLength - this.message.length;
      }
    },
    methods: {
      ...mapActions([
          "setMessagesChat",
          "sendMessageBackend",
          "resetMessagesChatStore",
          "setNewMessage",
          "setHistoryMessagesChat",
          "setConfirmReadMessage"
      ]),
      async handleScroll(event) {
        const chatContainer = this.$refs.chatContainer;

        if (chatContainer.scrollTop === 0 && this.loading === false) {
          this.currentPage = this.currentPage + 1;
          this.laoding = true;

          const oldScrollHeight = chatContainer.scrollHeight;

          if (this.$route.params.chatId != null && this.$route.params.userId != "") {
            const params = {
              "page": this.currentPage,
              "chatId": this.$route.params.chatId,
              "userId": this.$route.params.userId,
            };

            if(this.$route.params.token != null){
              params.token = this.$route.params.token;
            }

            this.setHistoryMessagesChat(params).then(() => {
              this.loading = false;

              this.$nextTick(() => {
                const newScrollHeight = chatContainer.scrollHeight;
                chatContainer.scrollTop = newScrollHeight - oldScrollHeight;
              });
            });
          }

        }
      },
      resetMessagesChat(){
        this.resetMessagesChatStore();
        this.confirmReadMessage();
      },
      scrollToBottom() {
        var chatBox = document.getElementById('chatMessage');
        chatBox.scrollTop = chatBox.scrollHeight;

        
        const chatContainer = this.$refs.containerChatMessage;
        if (chatContainer) {
          chatContainer.scrollTop = 999;
        }
      },
      setLastUserMessage(userId){
        this.userId = userId;
      },
      sendMessage(){
        if(this.$refs.message.value == ""){
          return;
        }

        const secret_message_id = this.$route.params.chatId;
        const message = this.$refs.message.value;
        const userId = this.$route.params.userId;

        this.sendMessageBackend({
          secret_message_id,
          message,
          userId
        });

        this.$refs.message.value = "";
        this.message = "";
      },
      confirmReadMessage(){
        const params = {
          "chatId": this.$route.params.chatId,
          "userId": this.$route.params.userId
        }

        this.setConfirmReadMessage(params);
      },
      enviarNotificacaoNativa(user, mensagemId) {

        if(user != this.$route.params.userId){
          if(!this.mensagensNotificadas.has(mensagemId)) {
            if (!this.abaAtiva) {
              if (Notification.permission === "granted") {
                new Notification("Elogio anônimo", { body: 'Você possui uma nova mensagem' });
                this.mensagensNotificadas.add(mensagemId);
              } else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(permission => {
                  if (permission === "granted") {
                    new Notification("Elogio anônimo", { body: 'Você possui uma nova mensagem' });
                    this.mensagensNotificadas.add(mensagemId);
                  }
                });
              }
            }
          }
        }

      }
    },
    mounted() {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        useTLS: true
      });

      const mensagensNotificadas = this.mensagensNotificadas;
      document.addEventListener('visibilitychange', () => {
        this.abaAtiva = !document.hidden;
        mensagensNotificadas.clear();
      });

      const pusher = this.pusher;
      const setNewMessage = this.setNewMessage;
      const scrollToBottom = this.scrollToBottom;
      const enviarNotificacaoNativa = this.enviarNotificacaoNativa;

      if (this.$route.params.chatId != null && this.$route.params.userId != "") {
        const params = {
          "page": this.currentPage,
          "chatId": this.$route.params.chatId,
          "userId": this.$route.params.userId,
        };

        if(this.$route.params.token != null){
          params.token = this.$route.params.token;
        }

        this.setMessagesChat(params).then(() => {
          this.scrollToBottom();
          this.loading = false;
        });

        if (pusher) {
          this.channel = pusher.subscribe(this.$route.params.chatId);

          this.messageHandler = function(data) {
            setNewMessage(data).then(() => {
              scrollToBottom();
            });

            enviarNotificacaoNativa(data.user_id, data.secret_message_id);
          };

          this.channel.bind(this.$route.params.chatId, this.messageHandler);
        }
      }

      if(this.$route.params.chatId != null){
        const params = {
          "chatId": this.$route.params.chatId,
          "userId": this.$route.params.userId
        }
        this.setConfirmReadMessage(params);
      }
    },
    unmounted() {
      if (this.pusher != null && this.channel != null) {
        this.channel.unbind(this.$route.params.chatId, this.messageHandler);
        this.pusher.unsubscribe(this.$route.params.chatId);
        this.channel = null;
      }
    }
};
</script>

<style scoped>

.style-custom-chat{
  z-index: 99999;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding: unset;
  margin: unset;
}

.total-height{
  height: 100%;
}

.custom-body-chat{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row > * {
  padding-left: unset !important;
  padding-right: unset !important;
}

.card-head-custom{
  height: 75px;
}

.style-form-custom{
  height: 85px;
}

.chat-list {
  height: calc(100vh - 240px);
  overflow-y: auto;
}

.custom-area-input{
  padding: 16px 20px !important;
  width: 95%;
}

.area-button-send-message{
  display: flex;
  justify-content: center;
  width: 5%;
}

@media only screen and (max-width: 1000px) {
  .custom-area-input{
    width: 85% !important;
  }

  .area-button-send-message{
    margin-top: unset !important;
    width: 15% !important;
    height: 100% !important;
  }

  .card-head-custom{
    margin-top: 20px !important;
  }
}

.custom-send-message{
  display: flex;
  justify-content: space-between;
  padding-right: unset !important;
  margin-top: 5px !important;
}

.input-message-custom{
  border-left: unset !important;
  font-size: 16px !important;
}

.button-send-message{
  height: 100% !important;
}

.area-button-send-message{
  height: 100% !important;
  position: absolute !important;
}

.card-dot-btn.dropdown-toggle {
  color: #000 !important;
  font-weight: bold !important;
}

.style-text-custom{
  text-align: start !important;
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.font-data-custom{
  color: #fff;
  font-size: 8pt !important;
}

.color-anonimo-chat{
  background-color: #3dc1d3 !important;
}

.right .color-anonimo-chat{
  background-color: #00a8ff !important;
}

.font-size-custom-message{
  font-size: 13pt;
  color: #ffffff !important;
  text-shadow: 1px 1px 1px #999;
}


@media only screen and (max-width: 1000px) {
  .customize-chat-mobile{
    position: fixed !important;
    width: 90%;
  }

  .customize-area-chat{
    position: fixed;
    bottom: env(safe-area-inset-bottom); /* Evita que o input seja coberto pela barra do navegador */
    left: 0;
    right: 0;
    /* padding: 10px; */
    background-color: #fff;
    z-index: 10;
  }
}

.character-counter {
  font-size: 0.9em;
  color: gray;
  padding-left: 5px;
}

</style>