<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-7 col-xxl-7 col-xxxl-7 maxWidthContainer">
      <div class="card border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing col-12">
          <div class="d-flex align-items-center flex-column">
            <img class="me-2" src="../../assets/images/favicon.png" width="80" alt="Layer">
            <h4 class="text-black fw-bold mb-0 text-center">
              Envie sua mensagem anônima:
            </h4>
          </div>
          <form ref="formRegister" @submit.prevent="submitRegister">
            <div class="row d-flex">

              <input v-model="messageId" ref="secretMessageId" class="d-none" />

              <div class="form-group mt-4 mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10 fs-16">
                  Whatsapp que irá receber a mensagem
                </label>
                <input
                  v-model="whatsapp"
                  ref="cellphone"
                  type="text"
                  name="cellphone"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="(xx) x xxxx-xxxx"
                  @keyup="changeInformationInputs('cellphone')"
                  @input="applyMaskPhone"
                  maxlength="15"
                  autocomplete="off"
                />
                <span ref="cellphone_error" style="display: none">Verifique o número informado se está completo DDD + NÚMERO</span>
              </div>

              <div class="form-group mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10 fs-16">
                  Mensagem anônima
                </label>
                <textarea
                  ref="secret_message"
                  type="text"
                  name="secret_message"
                  rows="7"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="Digite sua mensagem aqui"
                  v-model="message"
                  :maxlength="maxLength"
                />

                <div class="character-counter">
                  {{ remainingCharacters }} caracteres restantes
                </div>
              </div>

              
              <div
                class="form-check form-check-primary mb-0 fs-16 text-muted lh-1 mb-15 mb-md-20 col-12"
              >
                <label class="form-check-label fs-16" for="remember-me">
                  Enviar esta mensagem implica concordar com os nossos
                  <a href="/terms" target="_blank" class="text-decoration-none">termos de uso</a>
                  e também nossa
                  <a href="/privacy-policy" target="_blank" class="text-decoration-none">política de privacidade</a>
                </label>
              </div>

              <div class="form-group col-12">
                <button
                  class="default-btn transition border-0 fw-medium text-white rounded-1 fs-16 bg-success d-block w-100"
                  type="submit"
                  ref="button_submit_register"
                >
                  Enviar mensagem
                </button>
              </div>

              <div class="form-group col-12">
                <div class="message-error-register" v-if="getErrorRegister != ''">
                  {{ getErrorRegister }}
                </div>
              </div>

              <div class="form-group col-12">
                <span
                  class="d-block text-muted text-center mt-15 mt-md-30 mb-12 mb-md-20 fs-16"
                >
                  Já possui uma conta?
                </span>
                <router-link
                  to="/login"
                  class="with-border transition fw-medium rounded-1 fs-16 d-block w-100 text-center"
                >
                  Entrar
                </router-link>
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ref } from 'vue'

export default {
  name: "RegisterStepsContent",
  data() {
    return {
      message: "",
      whatsapp: "",
      maxLength: 1000,
      messageId: null
    };
  },
  computed:{
    ...mapGetters([
      'getErrorRegister'
    ]),
    remainingCharacters() {
      return this.maxLength - this.message.length;
    }
  },
  methods: {
    ...mapActions([
        "sendRegister"
    ]),
    changeInformationInputs(element){
      this.$refs[element].style.borderColor = "#dedee4";
    },
    applyMaskCpf(event) {
      const input = event.target;
      let value = input.value.replace(/\D/g, '');

      if (value.length <= 11) {
        value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      }

      input.value = value;
    },
    applyMaskPhone() {
      if (!this.whatsapp) return;

      // Remove qualquer caractere não numérico
      let value = this.whatsapp.replace(/\D/g, '');

      // Aplica a máscara: (XX) X XXXX-XXXX
      value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
      value = value.replace(/(\d{5})(\d)/, '$1-$2');

      // Atualiza o valor no modelo
      this.whatsapp = value;
    },
    hasLetters(str) {
      return /[a-zA-Z]/.test(str);
    },
    validateCaractersInputs(data){
      data.cellphone = data.cellphone.replace(/[!@#$(.) /-]/g, "");

      return data;
    },
    checkPassword(password){
      return /[0-9]/.test(password) && /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/.test(password);
    },
    submitRegister(){
      this.$refs["cellphone_error"].style.display = "none";
      const formData = new FormData(this.$refs['formRegister']);
      var data = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }

      data = this.validateCaractersInputs(data);

      let error = false;
      
      if(data.secret_message == ""){
        this.$refs["secret_message"].style.borderColor = "red";
        error = true;
      }
      
      if(data.cellphone == "" || this.hasLetters(data.cellphone) || data.cellphone.length < 10){
        this.$refs["cellphone"].style.borderColor = "red";
        this.$refs["cellphone_error"].style.display = "block";
        error = true;
      }

      if(error){
        return;
      }

      this.$refs.button_submit_register.innerHTML = "Verificando mensagem...";
      this.$refs.button_submit_register.disabled = true;

      if(this.messageId != null && this.messageId != ""){
        data.id = this.messageId;
      }
      this.sendRegister(data).then(() => {
        this.$refs.button_submit_register.innerHTML = "Enviar mensagem";
        this.$refs.button_submit_register.disabled = false;
      });
    }
  },
  mounted(){

    const secretAdmirerMessage = JSON.parse(localStorage.getItem('secretadmirermessage'));

    if(secretAdmirerMessage != null){
      if(secretAdmirerMessage.id != null){
        this.messageId = secretAdmirerMessage.id;
      }
      
      if(secretAdmirerMessage.whatsapp != null){
        this.whatsapp = secretAdmirerMessage.whatsapp;

        this.applyMaskPhone(); 
      }
      
      if(secretAdmirerMessage.message != null){
        this.message = secretAdmirerMessage.message;
      }

    }
  }
};
</script>

<style scoped>
.character-counter {
  margin-top: 5px;
  font-size: 0.9em;
  color: gray;
}
</style>