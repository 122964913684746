<template>
  <div class="row">
    <div class="col-12">
      <div
        class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing radius-7"
      >
        <div class="card-body">

          <div class="col-12">
            <div
              class="card border-0 rounded-0 bg-white order-summary-box letter-spacing radius-7 container-value-price"
            >
              <div class="card-head bg-white d-flex align-items-center card-checkout-title fs-16">
                <div class="d-flex">
                  <i class="flaticon-form font-22 color-font-general"></i>
                  <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15 fs-16 color-font-general">
                    Valor (1 conversa)
                  </h5>
                </div>
                <strong>R$ {{ getSummary.contracted_value }}</strong>
              </div>
            </div>
          </div>

          <form @submit.prevent="submitForm">

            <input v-model="chatId" ref="chatIdPaymentPending" class="d-none" />

            <!-- INFORMAÇÕES DA MENSAGEM -->
            <div class="row d-flex">
              <div class="form-group mt-4 mb-15 mb-20 mb-25 col-12">
                <label class="d-block text-black fw-semibold mb-10 fs-16">
                  Whatsapp que irá receber a mensagem
                </label>
                <input
                  v-model="whatsapp"
                  ref="cellphone"
                  type="text"
                  name="cellphone"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="(xx) x xxxx-xxxx"
                  @keyup="changeInformationInputs('cellphone')"
                  @input="applyMaskPhone"
                  maxlength="15"
                  autocomplete="new-password"
                />
                <span ref="cellphone_error" class="text-danger fw-bold" style="display: none">Verifique o número informado se está completo DDD + NÚMERO</span>
              </div>

              <div class="form-group mb-15 mb-sm-20 mb-md-25 col-12 col-sm-12 col-lg-12 col-xl-12 col-xxl-12 col-xxxl-12">
                <label class="d-block text-black fw-semibold mb-10 fs-16">
                  Mensagem anônima
                </label>
                <textarea
                  ref="secret_message"
                  @keyup="changeInformationInputs('secret_message')"
                  type="text"
                  name="secret_message"
                  rows="7"
                  class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                  placeholder="Digite sua mensagem aqui"
                  v-model="message"
                  :maxlength="maxLength"
                />

                <span ref="secret_message_error" class="text-danger fw-bold" style="display: none">Erro, verifique a mensagem e tente novamente</span>

                <div class="character-counter">
                  {{ remainingCharacters }} caracteres restantes
                </div>

              </div>
            </div>

            <!-- OPÇÕES DE PAGAMENTO -->
            <ul
              class="nav nav-tabs bg-white border-0 rounded-0 radius-7"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link custom-font-size fw-bold position-relative border-0 rounded-0 d-block w-100 height-100 active radius-7"
                  id="place-order-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#place-order-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="place-order-tab-pane"
                  aria-selected="false"
                >
                  <i class="flaticon-atm"></i>
                  PIX
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link custom-font-size fw-bold position-relative border-0 rounded-0 d-block w-100 radius-7"
                  id="payment-method-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#payment-method-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="payment-method-tab-pane"
                  aria-selected="true"
                >
                  <i class="flaticon-credit-card"></i>
                  Cartão de crédito
                </button>
              </li>

            </ul>

            <!-- PAGAMENTO NO CRÉDITO -->
            <div
              class="tab-content mt-4"
              id="myTabContent"
            >
              <div class="tab-pane fade"
                id="payment-method-tab-pane"
                role="tabpanel"
                tabindex="0"
              >
                <div id="paymentAccordion">
                  <div class="accordion-item rounded-0 radius-7 p-2">

                    <div v-if="getErrorPayment != ''" class="alert alert-danger d-flex align-items-center fs-md-15 fs-lg-16" role="alert">
                      <i class="flaticon-spam lh-1 fs-20 position-relative top-1 me-8"></i>
                      {{ getErrorPayment }}
                    </div>
                    
                    <div v-if="getSuccessPayment != ''" class="alert alert-success d-flex align-items-center fs-md-15 fs-lg-16" role="alert">
                      <i class="flaticon-party lh-1 fs-20 position-relative top-1 me-8"></i>
                      {{ getSuccessPayment }}
                    </div>
                    
                    <h4 class="mb-20 mt-4 fs-16 fw-bold">Preencha as informações abaixo para confirmar o pagamento via cartão de crédito:</h4>
                    <div>
                      <div class="mb-30 style-stripe card-element custom-font-size" id="card-element"></div>
                      <div id="card-errors" role="alert" style="color: red; font-size: 14px; margin-top: 10px;"></div>
                      <div class="text-center">
                        <button ref="buttonConfirmPaymentStripe" class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 custom-font-size bg-success fs-16 col-12 col-lg-4" type="submit">
                          Confirmar pagamento
                          <i class="flaticon-chevron position-relative ms-5 top-2"></i>
                        </button>
                      </div>
                    </div>
                      
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active"
                id="place-order-tab-pane"
                role="tabpanel"
                tabindex="0"
              >
                <div v-if="getErrorPayment != ''" class="alert alert-danger d-flex align-items-center fs-md-15 fs-lg-16" role="alert">
                  <i class="flaticon-spam lh-1 fs-20 position-relative top-1 me-8"></i>
                  {{ getErrorPayment }}
                </div>
                
                <div v-if="getSuccessPayment != ''" class="alert alert-success d-flex align-items-center fs-md-15 fs-lg-16" role="alert">
                  <i class="flaticon-party lh-1 fs-20 position-relative top-1 me-8"></i>
                  {{ getSuccessPayment }}
                </div>

                <div class="d-flex justify-content-center mt-4">
                  <div id="qrCodeContainer" class="mt-20 text-center qrcode-pix"></div>
                </div>
                
                <div v-bind:class="{'d-none': getStep != 'success'}" id="qrCodeContainerCopyAndPaster" class="mt-20 text-center pix-copia-e-cola">
                  <h5>Código Pix Copia e Cola:</h5>
                  <textarea
                    id="pixCode"
                    ref="pixCode"
                    readonly
                    class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                    style="resize: none; height: 80px; margin-top: 10px;"
                  ></textarea>
                  <button
                    id="copyPixCode"
                    type="button"
                    class="default-btn transition border-0 fw-medium text-white mt-3 pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-16 fs-16 col-12 col-lg-4"
                    v-bind:class="{'bg-success': this.copy == true}"
                    @click="copyPixCodeToClipboard"
                  >
                    {{  this.copy ? 'Código copiado !' : 'Copiar código pix' }}
                  </button>
                </div>

                <div v-if="getStep != 'success'" id="paymentAccordion" class="mt-4">
                  <div class="accordion-item rounded-0 radius-7 text-center">
                    <button type="button" ref="buttonConfirmPaymentMercadoPago" @click="checkoutPixPayment()" class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 custom-font-size bg-success col-12 col-lg-4">
                      Pagar com PIX
                      <i class="flaticon-chevron position-relative ms-5 top-2"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
                    
          </form>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { mapGetters, mapActions } from 'vuex';
import Pusher from 'pusher-js';

export default {
  name: "CheckoutContent",
  components: {
  },
  data() {
    return {
      stripe: null,
      cardElement: null,
      message: "",
      whatsapp: "",
      chatId: null,
      maxLength: 1000,
      copy: false,
      pusher: null,
      form: {
        name: '',
        email: '',
        company_id: ''
      }
    };
  },
  async mounted() {
    this.setCurrentMessagePaymentPending().then(() => {
      if(this.getCurrentMessagePaymentPending.message != null){
        this.message = this.getCurrentMessagePaymentPending.message;
      }
      
      if(this.getCurrentMessagePaymentPending.chat_id != null){
        this.chatId = this.getCurrentMessagePaymentPending.chat_id;
      }
      
      if(this.getCurrentMessagePaymentPending.whatsapp != null){
        this.whatsapp = this.getCurrentMessagePaymentPending.whatsapp;

        this.$nextTick(() => {
          this.applyMaskPhone(); 
        });
      }
    });

    this.setSummary().then(() => {
      if(this.getSummary.beginner){
        window.location.href = "/payment";
      }
    });

    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
    const elements = this.stripe.elements();

    var card = elements.create("card", {
      hidePostalCode: true,
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    });

    card.mount("#card-element");
    this.cardElement = card;
    this.cardElement.mount('#card-element');

    this.setRealtimeChannel().then(() => {

      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        useTLS: true
      });

      const pusher = this.pusher;

      if (this.getRealtimeChannel && pusher) {
        this.channel = pusher.subscribe(this.getRealtimeChannel);
        this.redirectPageMessages = function(data) {
          window.location.href = "/messages";
        };
        this.channel.bind("new-payment", this.redirectPageMessages);
      }

    });
  },
  unmounted() {
    if (this.pusher != null && this.channel != null) {
      this.channel.unbind('new-payment', this.redirectPageMessages);
      this.pusher.unsubscribe(this.getRealtimeChannel);
      this.channel = null;
    }

    document.removeEventListener('visibilitychange', () => {
      this.abaAtiva = !document.hidden;
    });
  },
  computed:{
    ...mapGetters([
      "getSuccessPayment",
      "getErrorPayment",
      "getSummary",
      "getCurrentMessagePaymentPending",
      "getStep",
      "getRealtimeChannel"
    ]),
    remainingCharacters() {
      return this.maxLength - this.message.length;
    }
  },
  methods: {
    ...mapActions([
      "setResetErrorPayment",
      "setProcessSubscriptionPayment",
      "setCheckoutPixPayment",
      "setSummary",
      "setCurrentMessagePaymentPending",
      "setCheckoutPixFirstPayment",
      "setRealtimeChannel"
    ]),
    copyPixCodeToClipboard() {
      const pixCode = this.$refs.pixCode;
      pixCode.select();
      document.execCommand("copy");
      this.copy = true;
      
      setTimeout(() => {
        this.copy = false;
      }, 4000);
      // alert("Código Pix Copia e Cola copiado!");
    },
    resetMessagesError(){
      this.$refs.cellphone_error.style.display = "none"
      this.$refs.secret_message_error.style.display = "none";
      
      if(this.$refs.cpfFieldAlert !== undefined){
        this.$refs.cpfFieldAlert.style.display = "none";
      }
      if(this.$refs.cep2FieldAlert !== undefined){
        this.$refs.cep2FieldAlert.style.display = "none";
      }
      if(this.$refs.city2FieldAlert !== undefined){
        this.$refs.city2FieldAlert.style.display = "none";
      }
      if(this.$refs.state2FieldAlert !== undefined){
        this.$refs.state2FieldAlert.style.display = "none";
      }
      if(this.$refs.number2FieldAlert !== undefined){
        this.$refs.number2FieldAlert.style.display = "none";
      }
    },
    checkoutPixPayment(){
      this.resetMessagesError();

      if (this.$refs.cellphone == null || this.$refs.cellphone.value.length != 15) {
        this.$refs.cellphone.style.borderColor = "red";
        this.$refs.cellphone_error.style.display = "block";
        return;
      }
      
      if (this.$refs.secret_message == null || this.$refs.secret_message.value.length == 0 || this.$refs.secret_message.value.length > 1000) {
        this.$refs.secret_message.style.borderColor = "red";
        this.$refs.secret_message_error.style.display = "block";
        return;
      }

      if (this.$refs.cpf !== undefined && this.$refs.cpf.value.length != 14) {
        this.$refs.cpfFieldAlert.style.display = "block";
        return;
      }

      if(this.$refs.cep2Field != null && this.$refs.cep2Field.value == ""){
        this.$refs.cep2FieldAlert.style.display = "block";
        return;
      }

      if(this.$refs.city2Field != null && this.$refs.city2Field.value == ""){
        this.$refs.city2FieldAlert.style.display = "block";
        return;
      }

      if(this.$refs.state2Field != null && this.$refs.state2Field.value == ""){
        this.$refs.state2FieldAlert.style.display = "block";
        return;
      }
      
      if(this.$refs.number2Field != null && this.$refs.number2Field.value == ""){
        this.$refs.number2FieldAlert.style.display = "block";
        return;
      }

      const buttonConfirmPaymentMercadoPago = this.$refs.buttonConfirmPaymentMercadoPago;
      buttonConfirmPaymentMercadoPago.disabled = true;
      buttonConfirmPaymentMercadoPago.innerHTML = "Processando pagamento..."
      
      const data = {};
      
      if(this.$refs.cep2Field != null){
        data.cep = this.$refs.cep2Field.value;
        data.street = this.$refs.street2Field.value;
        data.neighborhood = this.$refs.neighborhood2Field.value;
        data.city = this.$refs.city2Field.value;
        data.state = this.$refs.state2Field.value;
        data.number = this.$refs.number2Field.value;
      }

      data.chat_id = this.$refs.chatIdPaymentPending.value;
      data.cellphone = this.$refs.cellphone.value;
      data.secret_message = this.$refs.secret_message.value;

      if(this.$refs.cpf != null){
        data.cpf = this.$refs.cpf.value;
      }

      this.setCheckoutPixFirstPayment(data).then(() => {
        buttonConfirmPaymentMercadoPago.disabled = false;
        buttonConfirmPaymentMercadoPago.innerHTML = "Confirmar pagamento"
      });
    },
    changeInformationInputs(element){
      this.$refs[element].style.borderColor = "#dedee4";
    },
    applyMaskPhone() {
      if (!this.whatsapp) return;

      // Remove qualquer caractere não numérico
      let value = this.whatsapp.replace(/\D/g, '');

      // Aplica a máscara: (XX) X XXXX-XXXX
      value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
      value = value.replace(/(\d{5})(\d)/, '$1-$2');

      // Atualiza o valor no modelo
      this.whatsapp = value;
    },
    hasLetters(str) {
      return /[a-zA-Z]/.test(str);
    },
    async submitForm() {
      this.$refs["cellphone_error"].style.display = "none";
      
      const cellphone = this.$refs.cellphone.value.replace(/[!@#$(.) /-]/g, "");
      
      if (cellphone === "" || this.hasLetters(cellphone) || cellphone.length < 10) {
        this.$refs.cellphone.style.borderColor = "red";
        this.$refs["cellphone_error"].style.display = "block";
        return;
      }
      
      if (this.$refs.secret_message.value === "") {
        this.$refs.secret_message.style.borderColor = "red";
        return;
      }

      const buttonConfirmPaymentStripe = this.$refs.buttonConfirmPaymentStripe;
      const errorContainer = document.getElementById('card-errors'); // Contêiner para erros

      buttonConfirmPaymentStripe.disabled = true;
      buttonConfirmPaymentStripe.innerHTML = "Processando pagamento...";

      try {
        // Limpar erros anteriores
        this.setResetErrorPayment();
        errorContainer.textContent = ""; // Limpa o contêiner de erro

        // Criação do token do cartão
        const { token, error: errorCardElement } = await this.stripe.createToken(this.cardElement);

        let cardNumber = "";
        if (token && token.card && token.card.last4) {
          cardNumber = token.card.last4;
        }

        // Criação do método de pagamento
        const { paymentMethod, error: errorCreatePayment } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
          billing_details: {
            name: "",
          },
        });

        // Exibe erros, se houver
        if (errorCardElement) {
          errorContainer.textContent = errorCardElement.message;
          buttonConfirmPaymentStripe.disabled = false;
          buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";
          return;
        }

        if (errorCreatePayment) {
          errorContainer.textContent = errorCreatePayment.message;
          buttonConfirmPaymentStripe.disabled = false;
          buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";
          return;
        }

        // Nenhum erro: continua com o processamento
        const data = {
          cardCreated: paymentMethod.id,
          cardNumber: cardNumber,
          chat_id: this.$refs.chatIdPaymentPending.value,
          secret_message: this.$refs.secret_message.value,
          cellphone: cellphone,
        };

        await this.setProcessSubscriptionPayment(data);
        buttonConfirmPaymentStripe.disabled = false;
        buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";

      } catch (error) {
        // Exibir erro no console para depuração
        console.error(error);
        errorContainer.textContent = "Ocorreu um erro inesperado. Por favor, tente novamente.";
        buttonConfirmPaymentStripe.disabled = false;
        buttonConfirmPaymentStripe.innerHTML = "Confirmar pagamento";
      }
    }
  }
};
</script>
<style scoped>
/* Estilo para o formulário */


/* Estilo para o elemento do cartão */
.stripe-card-element {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
}

/* Estilos para estados do elemento do cartão */
.stripe-card-element input {
  font-size: 16px;
}

.stripe-card-element .StripeElement--invalid {
  border-color: #fa755a;
}

.stripe-card-element .StripeElement--focus {
  border-color: #4CAF50;
}

/* Estilo específico para o campo de número do cartão */
.InputElement {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 16px;
  background-color: #45a049 !;
}

.InputElement:focus {
  border-color: #4CAF50;
  outline: none;
}

.InputElement--invalid {
  border-color: #fa755a;
}

.InputElement--focus {
  border-color: #4CAF50;
}

.InputElement{
  background-color: #45a049;
}



.card-element {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

#card-errors {
  color: #fa755a;
}

.card-body{
  padding: 30px !important;
}

.card-checkout-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.character-counter {
  margin-top: 5px;
  font-size: 0.9em;
  color: gray;
}

.qrcode-pix{
  max-width: 400px;
}
</style>