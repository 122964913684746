import api from '../../config/axios.js';

const state = {
    payment: {},
    errorPayment: "",
    successPayment: "",
    step: "",
    summary: {
        "secret_messages_pending_payment_and_new_message": "Carregando...",
        "secret_messages_pending_payment": "Carregando...",
        "current_value_and_new_message": "Carregando...",
        "contracted_value": "Carregando...",
        "total_size": "Carregando...",
        "total_value": "Carregando...",
        "next_payment": "Carregando...",
        "quantity_items": "Carregando...",
        "enable_payment": true,
        "address_enabled": false
    },
    errorSummary: "",
    errorPaymentModeDefault: "",
    paymentModeDefault: [],
    loadingPaymentMode: true,
    errorPaymentHistory: "",
    paymentHistory: [],
    daysExportFiles: 10,
    servicePrice: "1,98",
    gbFree: 5,
}
const getters = {
    getPayment: state => state.payment,
    getErrorPayment: state => state.errorPayment,
    getSuccessPayment: state => state.successPayment,
    getSummary: state => state.summary,
    getErrorSummary: state => state.errorSummary,
    getErrorPaymentModeDefault: state => state.errorPaymentModeDefault,
    getPaymentModeDefault: state => state.paymentModeDefault,
    getLoadingPaymentMode: state => state.loadingPaymentMode,
    getErrorPaymentHistory: state => state.errorPaymentHistory,
    getPaymentHistory: state => state.paymentHistory,
    getDaysExportFiles: state => state.daysExportFiles,
    getServicePrice: state => state.servicePrice,
    getGbFree: state => state.gbFree,
    getStep: state => state.step,
}
const actions = {

    // REALIZANDO O PRIMEIRO PAGAMENTO COM CARTÃO DE CRÉDITO PELO MERCADO PAGO
    async setCheckoutCreditCardFirstPaymentMercadoPago({ commit }, data) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.post('/api/checkout/credit-card/first/payment', data, {headers: { Authorization: `Bearer ${token}` }}).catch(error => {
                if(error && error.response && error.response.data && error.response.data){
                    commit("setErrorPayment", error.response.data.message);
                }

            }).then(function(response){
                if(response && response.status == 200){
                    if(response.data.url != undefined && response.data.url != null && response.data.url){
                        window.location.href = response.data.url;
                    }

                    commit('setStep', response.data.step);

                    if(response.data.step == "success"){
                        commit('setSuccessPayment', response.data.message);
                        const { qrCode, qrCodeBase64 } = response.data;

                        const qrCodeContainer = document.getElementById("qrCodeContainer");
                        qrCodeContainer.innerHTML = `<img src="data:image/png;base64,${qrCodeBase64}" alt="QR Code Pix" />`;
                        
                        // Exibir o código Pix Copia e Cola
                        const pixCode = document.getElementById("pixCode");
                        pixCode.value = qrCode;

                        commit('setErrorPayment', "");
                        commit('setDisableAddressForm');
                        // window.location.href = "/messages";
                    }
                    
                    if(response.data.step == "address"){
                        commit('setErrorPayment', response.data.message);
                        commit('setEnableAddressForm');
                    }
                }

                if(response && response.status == 400){
                    if(response.data.step == "payment" || response.data.step == "fields" || response.data.step == "error"){
                        commit('setErrorPayment', response.data.message);
                    }
                    
                    if(response.data.step == "login"){
                        window.location.href = "/login";
                    }
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // REALIZANDO O PRIMEIRO PAGAMENTO
    async setCheckoutPixFirstPayment({ commit }, data) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){

            await api.axios.post('/api/checkout/pix/first/payment', data, {headers: { Authorization: `Bearer ${token}` }}).catch(error => {
                commit('setErrorPayment', "");
                if(error && error.response && error.response.data && error.response.data){
                    if(error.response.status == 400){
                        if(error.response.data.step == "payment" || error.response.data.step == "fields" || error.response.data.step == "error"){
                            commit('setErrorPayment', error.response.data.message);
                        }
                        
                        if(error.response.data.step == "login"){
                            window.location.href = "/login";
                        }
                    }
                }

            }).then(function(response){

                if(response && response.status == 200){
                    if(response.data.url != undefined && response.data.url != null && response.data.url){
                        window.location.href = response.data.url;
                    }

                    commit('setStep', response.data.step);

                    if(response.data.step == "success"){
                        commit('setSuccessPayment', response.data.message);
                        const { qrCode, qrCodeBase64 } = response.data;

                        const qrCodeContainer = document.getElementById("qrCodeContainer");
                        qrCodeContainer.innerHTML = `<img src="data:image/png;base64,${qrCodeBase64}" alt="QR Code Pix" />`;
                        
                        // Exibir o código Pix Copia e Cola
                        const pixCode = document.getElementById("pixCode");
                        pixCode.value = qrCode;

                        commit('setErrorPayment', "");
                        commit('setDisableAddressForm');
                        // window.location.href = "/messages";
                    }
                    
                    if(response.data.step == "address"){
                        commit('setErrorPayment', response.data.message);
                        commit('setEnableAddressForm');
                    }
                }

                
            });
        } else {
            window.location.href = "/login";
        }
    },
    // REALIZANDO o PAGAMENTO
    async setCheckoutPixPayment({ commit }, data) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.post('/api/checkout/pix', data, {headers: { Authorization: `Bearer ${token}` }}).catch(error => {
                if(error && error.response){
                    commit("setErrorPayment", error.response.data);
                }

            }).then(function(response){
                if(response && response.status == 200 && response.data.url){
                    // console.log("mercado pago: ", response.data.url);
                    window.location.href = response.data.url;
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    async setProcessSubscriptionFirstPayment({ commit }, data) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.post('/api/checkout/process/first/payment', data, {headers: { Authorization: `Bearer ${token}` }}).catch(error => {
                if(error && error.response && error.response.data && error.response.data){
                    commit("setErrorPayment", error.response.data);
                }

            }).then(function(response){
                if(response && response.status == 200){
                    if(response.data.step == "success"){
                        commit('setSuccessPayment', response.data.message);
                        window.location.href = "/messages";
                    }
                    
                    if(response.data.step == "address"){
                        commit('setErrorPayment', response.data.message);
                        commit('setEnableAddressForm');
                    }
                }
                
                if(response && response.status == 400){
                    if(response.data.step == "payment" || response.data.step == "fields" || response.data.step == "error"){
                        commit('setErrorPayment', response.data.message);
                    }
                    
                    if(response.data.step == "login"){
                        window.location.href = "/login";
                    }
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    async setProcessSubscriptionPayment({ commit }, data) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.post('/api/checkout/process', data, {headers: { Authorization: `Bearer ${token}` }}).catch(error => {
                if(error && error.response && error.response.data && error.response.data){
                    commit("setErrorPayment", error.response.data);
                }

            }).then(function(response){
                if(response && response.status == 200){
                    commit('setSuccessPayment', "pagamento confirmado, o serviço já está disponibilizado !");
                    window.location.href = "/messages";
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // ZERANDO MENSAGEM DE ERRO NO PROCESSAMENTO DE PAGAMENTO
    setResetErrorPayment({ commit }){
        commit("setErrorPayment", "");
    },
    // BUSCANDO O DÉBITO DO CLIENTE
    async setSummary({ commit }, data) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.post('/api/checkout/summary', data, {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                
                commit("setErrorSummary", "Erro ao buscar informações de pagamento, por favor tente novamente mais tarde !");

            }).then(function(response){
                if(response.status == 200){
                    if((response.data.secret_messages_pending_payment == undefined || response.data.secret_messages_pending_payment == 0) && window.location.pathname !== "/new-message"){
                        window.location.href = "/messages";
                    }
                    commit('setSummary', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // BUSCANDO A QUANTIDADE DE DIAS QUE OS ARQUIVOS FICARAM NA NUVEM APÓS O CANCELAMENTO DO SERVIÇO
    async setDaysExportFiles({ commit }) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/export/files/finish', {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                
                commit("setDaysExportFiles", 10);

            }).then(function(response){
                if(response && response.status == 200){
                    commit('setDaysExportFiles', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // BUSCANDO O VALOR DO SERVIÇO
    async setServicePrice({ commit }) {
        await api.axios.get('/api/service/price').catch(function(){
            
            commit("setServicePrice", false);

        }).then(function(response){
            if(response && response.status == 200){
                commit('setServicePrice', response.data);
            }
        });
    },
    // BUSCANDO OS GIGAS LIVRES
    async setGbFree({ commit }) {
        await api.axios.get('/api/gb/free').catch(function(){
            
            commit("setGbFree", 5);

        }).then(function(response){
            if(response && response.status == 200){
                commit('setGbFree', response.data);
            }
        });
    },
    // BUSCANDO MODO DE PAGAMENTO PADRÃO DO CLIENTE
    async setPaymentModeDefault({ commit }) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.post('/api/payment/mode', null, {headers: { Authorization: `Bearer ${token}` }}).catch(function(data){
                commit("setErrorPaymentModeDefault", "Erro ao buscar forma de pagamento padrão, por favor tente novamente mais tarde !");
                
                if(data.response && data.response.status == 401){
                    window.location.href = "/login";
                }

            }).then(function(response){
                commit('setLoadingPaymentMode', false);
                if(response && response.status == 200){
                    commit('setPaymentModeDefault', response.data);
                }
                
            });
        } else {
            window.location.href = "/login";
        }
    },
    // BUSCANDO HISTÓRICO DE PAGAMENTOS DO CLIENTE
    async setPaymentHistory({ commit }, data) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.post('/api/payment/history', data, {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                
                commit("setErrorPaymentHistory", "Erro ao buscar histórico de pagamentos do cliente, por favor tente novamente mais tarde !");

            }).then(function(response){
                if(response && response.status == 200){
                    commit('setPaymentHistory', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
}
const mutations = {
    setProcessSubscriptionPayment: function(state, data){
        state.payment = data;
    },
    setErrorPayment: function(state, data){
        state.errorPayment = data;
    },
    setSuccessPayment: function(state, data){
        state.successPayment = data;
    },
    setErrorSummary: function(state, data){
        state.errorSummary = data;
    },
    setSummary: function(state, data){
        state.summary = data;
    },
    setErrorPaymentModeDefault: function(state, data){
        state.errorPaymentModeDefault = data;
    },
    setPaymentModeDefault: function(state, data){
        state.paymentModeDefault = data;
    },
    setLoadingPaymentMode: function(state, data){
        state.loadingPaymentMode = data;
    },
    setErrorPaymentHistory: function(state, data){
        state.errorPaymentHistory = data;
    },
    setPaymentHistory: function(state, data){
        state.paymentHistory = data;
    },
    setDaysExportFiles: function(state, data){
        state.daysExportFiles = data;
    },
    setServicePrice: function(state, data){
        state.servicePrice = data;
    },
    setGbFree: function(state, data){
        state.gbFree = data;
    },
    setEnableAddressForm: function(state){
        state.summary.address_enabled = true;
    },
    setDisableAddressForm: function(state){
        state.summary.address_enabled = false;
    },
    setStep: function(state, data){
        state.step = data;
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}