import api from '../../config/axios.js';
import moment from 'moment';

const state = {
    id: '',
    name: "",
    cell_phone: "",
    commercial_phone: "",
    active: "",
    email: "",
    company_id: "",
    created_at: "",
    configurations: {},
    errorRegister: "",
    errorForgotPasswordSendEmail: "",
    configurationSftp: [],
    currentConfigurationSftp: {},
    usersList: [],
    currentDeleteUser: {},
    setVerifyTokenLogin: "",
    currentMessagePaymentPending: {
        "chat_id": null,
        "whatsapp": null,
        "message": null
    },
    sessionMessage: {}
}
const getters = {
    getId: state => state.id,
    getName: state => state.name,
    getPhone: state => state.phone,
    getBirthday: state => (state.birthday != null) ? moment(state.birthday).format('DD/MM/YYYY') : 'Não Informado',
    getActive: state => state.active,
    getEmail: state => state.email,
    getCompany_id: state => state.company_id,
    getCreatedAt: state => (state.created_at != null) ? moment(state.created_at).format('DD/MM/YYYY') : 'Não Informado',
    getConfigurations: state => state.configurations,
    getErrorRegister: state => state.errorRegister,
    getErrorForgotPasswordSendEmail: state => state.errorForgotPasswordSendEmail,
    getConfigurationSftp: state => state.configurationSftp,
    getCurrentConfigurationSftp: state => state.currentConfigurationSftp,
    getUsersList: state => state.usersList,
    getCurrentDeleteUser: state => state.currentDeleteUser,
    getVerifyTokenLogin: state => state.verifyTokenLogin,
    getCurrentMessagePaymentPending: state => state.currentMessagePaymentPending,
    getSessionMessage: state => state.sessionMessage
}
const actions = {
    // REALIZANDO CRIAÇÃO DA EMPRESA/USUÁRIO
    async sendRegister({ commit }, obj){
        await api.axios.post('/api/register/step/one', obj).catch((error) => {
            if(error.response && error.response.data){
                commit("setErrorRegister", error.response.data);
            }
        }).then(response => {
            if(response && response.data){
                // console.log("token retornado: ", response.data.token);
                localStorage.setItem('secretadmirermessage', JSON.stringify(response.data));
                commit("setSessionMessage");
                window.location.href = "/createaccess";
            }
        });
    },
    // REALIZANDO CRIAÇÃO DA EMPRESA/USUÁRIO NO STEP 2
    async sendRegisterTwo({ commit }, obj){
        await api.axios.post('/api/register/step/two', obj).catch((error) => {
            if(error.response && error.response.data){
                commit("setErrorRegister", error.response.data);
            }
        }).then(response => {
            if(response && response.data){
                localStorage.removeItem('secretadmirermessage');
                localStorage.setItem('secretadmirertoken', response.data.token);
                commit("setTokenSession");
            }
        });
    },
    // ENVIAR EMAIL DE ESQUECI MINHA SENHA
    async sendForgotPasswordSendEmail({ commit }, obj){
        await api.axios.post('/api/reset/password/email', {email: obj.email}).catch((error) => {
            if(error.response && error.response.data){
                commit("setErrorForgotPasswordSendEmail", error.response.data);
            }
        }).then(response => {
            if(response && response.status == 200){
                obj.router.push('/send-email-forgot-password');
            }
        });
    },
    // SETANDO ERRO EM VALIDAÇÕES FORGOT PASSWORD
    async setErrorForgotPasswordSendEmail({ commit }, message){
        commit("setErrorForgotPasswordSendEmail", message);
    },
    // REALIZANDO LOGIN
    async sendCreateSession(obj){
        await window.axios.post('/api/login', obj).catch(() => {
            // console.log("tratar erro de login");
        }).then(response => {
            if(response && response.data && response.data.token != undefined){
                localStorage.setItem('secretadmirertoken', response.data.token);
                window.location.href = "/dashboard";
            }         
        });
    },
    // REALIZAR LOGOFF
    async logoffSession() {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await window.axios.post('/api/logout', {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                // console.log("erro ao realizar logoff");
            }).then(function(response){
                try {
                    if(response.status == 200){
                        localStorage.removeItem("secretadmirertoken");
                        window.location.href = "/login";
                    } else {
                        localStorage.removeItem("secretadmirertoken");
                        window.location.href = "/login";
                    }
                } catch(error){
                    localStorage.removeItem("secretadmirertoken");
                    window.location.href = "/login";
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // RECUPERANDO CONFIGURAÇÕES SFTP
    async setConfigurationSftp({commit}) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/configurations/sftp', {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                // console.log("erro ao recuperar configurações sftp");
            }).then(function(response){
                if(response.status == 200){
                    commit('setConfigurationSftp', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // SALVANDO A NOVA CONFIGURAÇÃO DE UPLOAD VIA SFTP
    async saveConfigurationSftp({commit}, obj) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){

            await api.axios.post('/api/save/configurations/sftp', obj, {headers: { Authorization: `Bearer ${token}` }}).catch(function(error){
                // console.log("erro", error);
            }).then(function(response){
                // console.log("response", response);
            });

        } else {
            window.location.href = "/login";
        }
    },
    // BUSCAR SESSAO
    async reloadSession({ commit }) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await window.axios.post('/api/user', {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                // console.log("erro ao recuperar sessão");
                if(window.location.pathname != "/login" && window.location.pathname != "/"){
                    window.location.href = "/login";
                }
            }).then(function(response){
                if(response.status == 200){
                    commit('sendCreateSession', response.data);
                    window.location.href = "/dashboard";
                }
            });
        } else {
            if(window.location.pathname != "/login" && window.location.pathname != "/"){
                window.location.href = "/login";
            }
        }
    },
    // BUSCAR CONFIGURAÇÕES
    async setConfiguration({ commit }) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/configurations', {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                if(window.location.pathname != "/home" && window.location.pathname != "/login" && window.location.pathname != "/" && window.location.pathname != "/register" && window.location.pathname != "/forgot-password" && window.location.pathname != "/send-email-forgot-password" && window.location.pathname != "/terms"){
                    window.location.href = "/login";
                }
            }).then(function(response){
                if(response.status == 200){
                    commit('setConfiguration', response.data);
                }
            });
        } else {
            if(window.location.pathname != "/home" && window.location.pathname != "/login" && window.location.pathname != "/" && window.location.pathname != "/register" && window.location.pathname != "/forgot-password" && window.location.pathname != "/send-email-forgot-password" && window.location.pathname != "/terms"){
                window.location.href = "/login";
            }
        }
    },
    reloadData({ commit }, data) {
        commit('sendCreateSession', data);
    },
    setNewToken({ commit }, data){
        commit("setNewToken", data);
    },
    setCurrentConfigurationSftp({ commit }, data){
        commit("setCurrentConfigurationSftp", data);
    },
    // LISTANDO USUÁRIOS
    async setUsersList({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/users', {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                commit("setUsersList", []);
            }).then(response => {
                if(response && response.data){
                    commit("setUsersList", response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    // VALIDANDO TOKEN DO LOGIN DO USUÁRIO QUANDO ELE ENTRA EM QUALQUER TELA
    async setVerifyTokenLogin({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/users', {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                localStorage.removeItem("secretadmirertoken");
                commit("setVerifyTokenLogin", "401");
            }).then(response => {
                if(response && response.data){
                    commit("setVerifyTokenLogin", "200");
                }
            });
        } else {
            commit("setVerifyTokenLogin", "401");
        }
    },
    async setCurrentDeleteUser({ commit }, data){
        commit('setCurrentDeleteUser', data);
    },
    async setCurrentMessagePaymentPending({ commit }){
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){

            await api.axios.get('/api/message/payment/pending', {headers: { Authorization: `Bearer ${token}` }}).catch((error) => {
                // console.log(error);
            }).then(response => {
                if(response && response.data){
                    commit("setCurrentMessagePaymentPending", response.data);
                }
            });

        } else {
            window.location.href = "/login";
        }
    },
}
const mutations = {
    // SETANDO INFORMACOES DE LOGIN
    sendCreateSession: function(state, data){
        state.id = data.id;
        state.name = data.name;
        state.cell_phone = data.cell_phone;
        state.commercial_phone = data.commercial_phone;
        state.active = data.active;
        state.email = data.email;
        state.company_id = data.company_id;
        state.created_at = data.created_at;
    },
    //BUSCANDO CONFIGURAÇÕES ATUAIS
    setConfiguration: function(state, data){
        state.configurations = data;
    },
    setNewToken: function(state, data){
        state.configurations.companyToken = data;
    },
    setTokenSession: function(){
        window.location.href = "/messages";
    },
    setErrorRegister: function(state, data){
        state.errorRegister = data;
    },
    setErrorForgotPasswordSendEmail: function(state, data){
        state.errorForgotPasswordSendEmail = data;
    },
    setConfigurationSftp: function(state, data){
        state.configurationSftp = data;
    },
    setCurrentConfigurationSftp: function(state, data){
        state.currentConfigurationSftp = data;
    },
    setUsersList: function(state, data){
        state.usersList = data;
    },
    setCurrentDeleteUser: function(state, data){
        state.currentDeleteUser = data;
    },
    setVerifyTokenLogin: function(state, data){
        state.verifyTokenLogin = data;
    },
    setCurrentMessagePaymentPending: function(state, data){
        state.currentMessagePaymentPending = data;
    },
    setSessionMessage: function(state, data){
        state.setSessionMessage = data;
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}