<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6 maxWidthContainer">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card radius-7">
        <div class="card-body letter-spacing d-flex flex-column align-items-center">

          <div class="icon position-relative rounded-circle text-center text-primary">
            <i class="flaticon-envelope"></i>
          </div>

          <p class="mb-0 fs-16 text-paragraph text-center sub-text mb-30 mt-30">
            Enviamos um e-mail para o endereço informado, <br>por favor verifique sua caixa de entrada.
          </p>
          <div class="text-center mt-15 mt-sm-20 mt-md-25">
            <router-link
              to="/login"
              class="link-btn text-decoration-none text-primary fw-medium fs-16 position-relative"
            >
              <i class="ph-bold ph-arrow-left"></i>
              Voltar a tela de login
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordSendEmail",
};
</script>