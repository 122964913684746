<template>
  <div class="app">
    <div v-if="!loading">
      <MainHeader v-if="isLogged && currentRoute !== '/'" />
      <MainSidebar v-if="isLogged && currentRoute !== '/'" />
      <div class="main-content d-flex flex-column transition overflow-hidden" :class="{'center-main-content': !isLogged, 'justify-content-start validate-home-page': currentRoute === '/'}">
        <router-view />
        <MainFooter v-if="isLogged && currentRoute !== '/'" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, watchEffect } from "vue";
import { mapGetters, mapActions } from 'vuex';
import { useRoute } from 'vue-router';
import stateStore from "./utils/store";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";

export default defineComponent({
  name: "App",
  data () {
    return {
      isLogged: false,
      loading: true
    }
  },
  components: {
    MainHeader,
    MainSidebar,
    MainFooter,
  },
  computed:{
    ...mapGetters([
      "getVerifyTokenLogin",
    ])
  },
  methods: {
    ...mapActions([
      "setVerifyTokenLogin"
    ])
  },
  created() {
    this.setVerifyTokenLogin().then(() => {
      if(this.getVerifyTokenLogin == 401 && !window.location.pathname.startsWith("/chat/") && !window.location.pathname.startsWith("/") && window.location.pathname != "/login"){
          window.location.href = "/login";
          return;
      }
      
      if(localStorage.getItem('secretadmirertoken')){
        if(window.location.pathname === "/" || window.location.pathname === "/login" || window.location.pathname === "/forgot-password" || window.location.pathname === "/send-email-forgot-password" || window.location.pathname === "/terms" || window.location.pathname === "/privacy-policy"){
          window.location.href = "/messages";
          return;
        } else if(window.location.pathname === "/register") {
          window.location.href = "/messages";
          return;
        }

        this.isLogged = true;
        this.loading = false;
      } else {
        this.isLogged = false;
        if(!window.location.pathname.startsWith("/chat/") && window.location.pathname !== "/" && window.location.pathname !== "/login" && window.location.pathname !== "/createaccess" && window.location.pathname !== "/register" && window.location.pathname !== "/forgot-password" && window.location.pathname !== "/send-email-forgot-password" && window.location.pathname !== "/terms" && window.location.pathname !== "/privacy-policy"){
          window.location.href = "/login";
        }

        // if(window.location.pathname === "/register" && localStorage.getItem('secretadmirermessage') != null){
        //   const secretAdmirerMessage = JSON.parse(localStorage.getItem('secretadmirermessage'));

        //   if(secretAdmirerMessage != null){
        //     if(secretAdmirerMessage.id != null && secretAdmirerMessage.message != null && secretAdmirerMessage.whatsapp != null){
        //       window.location.href = "/createaccess";
        //     }
        //   }
        // }

        this.loading = false;
      }
    });
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");
  },
  setup() {
    const route = useRoute();
    const stateStoreInstance = stateStore;
    
    const currentRoute = computed(() => route.path);

    watchEffect(() => {
      if (stateStoreInstance.open) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
      }
    });

    return {
      currentRoute
    };
  },
});
</script>
<style scoped>
.validate-home-page{
  padding: unset !important;
}
</style>