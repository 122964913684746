<template>
  <div class="loading" v-if="loading">
    <h3>Carregando...</h3>
  </div>
  <div v-for="(value, key) in getMessages" :key="key" class="card mb-25 border-0 radius-7 bg-white stats-item">
    <router-link
      :to="value.payment == 0 ? '/payment' : '/chat/'+value.id+'/'+getRealtimeChannel"
      class="link-btn text-decoration-none text-primary fw-medium fs-16 position-relative"
    >
      <div class="card-body p-15 p-sm-20 p-md-25 pt-lg-30 letter-spacing align-items-center d-flex">
        
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-5 flex-card-custom">
            <div
              class="icon position-relative text-success rounded-1 text-center photo-chat area-message-photo"
            >
              <i class="flaticon-user-3"></i>
            </div>

            <div class="flex-column ml-10 info text-content-card area-message-content">
              <h5 class="fw-black mb-0 me-10 lh-1">{{ value.whatsapp }}</h5>

              <span v-if="value.payment == 0" class="style-payment-pending fs-16">Pagamento pendente</span>
              <span v-if="value.payment == 1 && value.status == 0" class="style-sent-pending fs-16">Envio pendente</span>

              <div class=" width-100 mt-2">
                <span class="fs-16 d-block text-dark-emphasis fw-bold">
                  {{ value.messages[0].message }}
                </span>
              </div>
              
              <div v-if="value.new_message_alert" class="custom-new-message fs-16 fw-bold">Você possui uma nova mensagem</div>
            </div>
          </div>

        </div>

      </div>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters, mapActions } from 'vuex';
import Pusher from 'pusher-js';

export default defineComponent({
  name: "UploadSuccessCard",

  data: function () {
    return {
      loading: true,
      pusher: null,
      abaAtiva: true,
      mensagensNotificadas: new Set(),
    };
  },
  methods: {
    ...mapActions([
        "setMessages",
        "setRealtimeChannel",
        "setPaymentModeDefault",
        "setNewMessageAlert",
        "setSendMessageEvent"
    ]),
    enviarNotificacaoNativa(mensagemId) {
      if (!this.mensagensNotificadas.has(mensagemId)) {
        if (!this.abaAtiva) {
          if (Notification.permission === "granted") {
            new Notification("Elogio anônimo", { body: 'Você possui uma nova mensagem' });
            this.mensagensNotificadas.add(mensagemId);
          } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(permission => {
              if (permission === "granted") {
                new Notification("Elogio anônimo", { body: 'Você possui uma nova mensagem' });
                this.mensagensNotificadas.add(mensagemId);
              }
            });
          }
        }
      }
    }
  },
  computed:{
    ...mapGetters([
      'getMessages',
      'getRealtimeChannel',
      'getPaymentModeDefault'
    ])
  },
  mounted() {

    if (Notification.permission !== "granted") {
        Notification.requestPermission();
    }

    this.setPaymentModeDefault().then(() => {

      if(this.getPaymentModeDefault.length == 0){
        window.location.href = "/payment";
      }
      this.setMessages().then(() => {
        this.loading = false;
      });
    });

    this.setRealtimeChannel().then(() => {

      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        useTLS: true
      });
      
      const pusher = this.pusher;
      const setNewMessageAlert = this.setNewMessageAlert;
      const setSendMessageEvent = this.setSendMessageEvent;
      const enviarNotificacaoNativa = this.enviarNotificacaoNativa;
      const mensagensNotificadas = this.mensagensNotificadas;

      document.addEventListener('visibilitychange', () => {
        this.abaAtiva = !document.hidden;
        mensagensNotificadas.clear();
      });
      
      if (this.getRealtimeChannel && pusher) {

        this.channel = pusher.subscribe(this.getRealtimeChannel);

        this.messageHandler = function(data) {
          setNewMessageAlert(data);
          enviarNotificacaoNativa(data.secret_message_id);
        };
        
        this.channel.bind("new-message", this.messageHandler);

        this.sendMessageWhatsapp = function(data) {
          setSendMessageEvent(data);
        };
        
        this.channel.bind("new-send-message", this.sendMessageWhatsapp);
      }

    });
  },
  unmounted() {
    if (this.pusher != null && this.channel != null) {
      this.channel.unbind('new-message', this.messageHandler);
      this.pusher.unsubscribe(this.getRealtimeChannel);
      this.channel = null;
    }

    document.removeEventListener('visibilitychange', () => {
      this.abaAtiva = !document.hidden;
    });
  }
});
</script>


<style scoped>

.photo-chat{
  border-radius: 100px !important;
}

.text-content-card{
  display: flex;
  justify-content: center;
  width: 90%;
}


@media only screen and (max-width: 1000px) {
  .area-message-content{
    width: calc(100% - 75px);
  }
}

.loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.custom-new-message{
  background-color: #00a8ff;
  color: #fff;
  padding: 7px;
  border-radius: 40px;
  text-align: center;
}

.area-message-photo{
  flex: 0 0 auto !important;
}

.style-payment-pending{
  color: #fff;
  background-color: chocolate;
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: bold;
}

.style-sent-pending{
  color: #fff;
  background-color: #40739e;
  border-radius: 30px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: bold;
  width: fit-content;
}
</style>