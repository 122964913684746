<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6 maxWidthContainer">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card radius-7">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">Esqueceu sua senha ?</h4>
          <p class="mb-0 fs-16 text-paragraph text-center sub-text">
            Digite seu endereço de e-mail abaixo e lhe enviaremos um email de recuperação
          </p>
          <form ref="formForgotPassword" @submit.prevent="submitForgotPassword">
            <div class="form-group mb-15 mb-sm-20">
              <label class="d-block text-black fw-semibold mb-10 fs-16">
                Email
              </label>
              <input
                type="email"
                name="email"
                class="form-control shadow-none rounded-0 text-black radius-7 custom-font-size"
                placeholder="nome@gmail.com"
              />
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 d-block w-100 radius-7 bg-success custom-font-size fs-16"
              type="submit"
              ref="button_submit_forgot"
            >
              Enviar
            </button>

            <div v-if="getErrorForgotPasswordSendEmail != ''" ref="error_forgot_password_send_email" class="alert alert-danger custom-font-size message-error-login fs-16" role="alert">
              {{ getErrorForgotPasswordSendEmail }}
            </div>

            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium custom-font-size position-relative fs-16"
              >
                <i class="ph-bold ph-arrow-left"></i>
                Voltar a tela de login
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "ForgotPassword",
  computed:{
    ...mapGetters([
      "getErrorForgotPasswordSendEmail"
    ])
  },
  methods: {
    ...mapActions([
        "sendForgotPasswordSendEmail",
        "setErrorForgotPasswordSendEmail"
    ]),
    submitForgotPassword(){
      const formData = new FormData(this.$refs['formForgotPassword']);
      var data = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val })
      }

      let error = false;

      if(data.email == ''){
        this.setErrorForgotPasswordSendEmail("Erro, por favor informe um email válido !");
        setTimeout(() => {
          this.setErrorForgotPasswordSendEmail("");
        }, 4000);
        error = true;
      } else {
        this.setErrorForgotPasswordSendEmail("");
      }

      if(error){
        return;
      }

      const obj = {
        email: data.email,
        router: this.$router
      }

      this.$refs.button_submit_forgot.innerHTML = "Carregando...";
      this.$refs.button_submit_forgot.disabled = true;

      this.sendForgotPasswordSendEmail(obj).then(() => {
        this.$refs.button_submit_forgot.innerHTML = "Enviar";
        this.$refs.button_submit_forgot.disabled = false;
      });
    }
  }
};
</script>