import api from '../../config/axios.js';
import moment from 'moment';

function sortListMessages (messages) {
    // console.log(messages.sort((a, b) => a.b.new_message_alert - b.b.new_message_alert));
    return messages.sort((a, b) => b.new_message_alert - a.new_message_alert);
}

const state = {
    whatsapp: "",
    messages: [],
    messagesChat: []
}
const getters = {
    getWhatsapp: state => state.whatsapp,
    getMessages: state => state.messages,
    getMessagesChat: state => state.messagesChat
}
const actions = {

    async setSendMessageEvent({commit}, data) {
        commit('setSendMessageEvent', data);
    },
    async setConfirmReadMessage({commit},params) {
        await api.axios.post('/api/confirm/read/message', params);
    },
    async setMessages({commit}) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            await api.axios.get('/api/messages', {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                // console.log("erro ao recuperar as mensagens do usuário");
            }).then(function(response){
                if(response.status == 200){
                    commit('setMessages', response.data);
                }
            });
        } else {
            window.location.href = "/login";
        }
    },
    async setNewMessageAlert({commit}, data) {
        commit('setNewMessageAlert', data);
    },
    async setMessagesChat({commit}, params) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            if(params.token){
                await api.axios.get('/api/messages/chat/' + params.chatId + '/' + params.userId + '/' + params.token + '?page=' + params.page).catch(function(){
                    // console.log("erro ao recuperar as mensagens do chat");
                }).then(function(response){
                    if(response.status == 200){
                        commit('setMessagesChat', response.data);
                    }
                });
            } else {
                await api.axios.get('/api/messages/chat/' + params.chatId + '/' + params.userId + '?page=' + params.page, {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                    // console.log("erro ao recuperar as mensagens do chat");
                }).then(function(response){
                    if(response.status == 200){
                        commit('setMessagesChat', response.data);
                    }
                });
            }
        } else {
            await api.axios.get('/api/messages/chat/' + params.chatId + '/' + params.userId + '/' + params.token + '?page=' + params.page).catch(function(){
                // console.log("erro ao recuperar as mensagens do chat");
            }).then(function(response){
                if(response.status == 200){
                    commit('setMessagesChat', response.data);
                }
            });
        }
    },
    async setHistoryMessagesChat({commit}, params) {
        const token = localStorage.getItem("secretadmirertoken");
        if(token != undefined && token != null && token){
            if(params.token){
                await api.axios.get('/api/messages/chat/' + params.chatId + '/' + params.userId + '/' + params.token + '?page=' + params.page).catch(function(){
                    // console.log("erro ao recuperar as mensagens do chat");
                }).then(function(response){
                    if(response.status == 200){
                        commit('setHistoryMessagesChat', response.data);
                    }
                });
            } else {
                await api.axios.get('/api/messages/chat/' + params.chatId + '/' + params.userId + '?page=' + params.page, {headers: { Authorization: `Bearer ${token}` }}).catch(function(){
                    // console.log("erro ao recuperar as mensagens do chat");
                }).then(function(response){
                    if(response.status == 200){
                        commit('setHistoryMessagesChat', response.data);
                    }
                });
            }
        } else {
            await api.axios.get('/api/messages/chat/' + params.chatId + '/' + params.userId + '/' + params.token + '?page=' + params.page).catch(function(){
                // console.log("erro ao recuperar as mensagens do chat");
            }).then(function(response){
                if(response.status == 200){
                    commit('setHistoryMessagesChat', response.data);
                }
            });
        }
    },
    async sendMessageBackend({commit}, params) {
        await api.axios.post('/api/send/message', params).catch(function(){
            // console.log("erro ao enviar as mensagens do chat");
        }).then(function(response){
            if(response.status == 200){
                // commit('sendMessageBackend', response.data);
            }
        });
    },
    async setNewMessage({commit}, data){
        commit("sendMessageBackend", data);
    },
    async resetMessagesChatStore({commit}){
        commit("resetMessagesChatStore");
    },
    async resetAllChats({commit}){
        commit("resetAllChats");
    }

}
const mutations = {

    setMessages: function(state, data){
        state.messages = sortListMessages(data);
    },
    setMessagesChat: function(state, data){
        if(data){
            data.forEach(element => {
                state.whatsapp = element.whatsapp;
            });
        }

        state.messagesChat = data.reverse();
    },
    setHistoryMessagesChat: function(state, data){
        if(data){
            data.forEach(element => {
                state.whatsapp = element.whatsapp;
            });
        }
        
        state.messagesChat = [...data.reverse(), ...state.messagesChat];
    },
    sendMessageBackend: function(state, data){
        state.messagesChat.push(data);
    },
    resetMessagesChatStore: function(state){
        state.messagesChat = [];
    },
    resetAllChats: function(state){
        state.messages = [];
    },

    setNewMessageAlert: function(state, data){
        state.messages.forEach(value => {
            if(value.id == data.secret_message_id){
                value.new_message_alert = true;
                value.messages = [{"message": data.message}];
            }
        });

        state.messages = sortListMessages(state.messages);
    },
    setSendMessageEvent: function(state, data){
        state.messages.forEach(value => {
            if(value.id == data.secret_message_id){
                value.new_message_alert = false;
                value.payment = 1;
                value.status = 1;
            }
        });

        state.messages = sortListMessages(state.messages);
    }

}
export default {
    state,
    getters,
    actions,
    mutations
}